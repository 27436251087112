// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-app-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-appclassic-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/appclassic.js" /* webpackChunkName: "component---src-pages-appclassic-js" */),
  "component---src-pages-charity-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/charity.js" /* webpackChunkName: "component---src-pages-charity-js" */),
  "component---src-pages-crypto-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-hosting-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interior-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/interior.js" /* webpackChunkName: "component---src-pages-interior-js" */),
  "component---src-pages-page-2-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-ride-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/ride.js" /* webpackChunkName: "component---src-pages-ride-js" */),
  "component---src-pages-saas-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-saasclassic-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/saasclassic.js" /* webpackChunkName: "component---src-pages-saasclassic-js" */),
  "component---src-pages-saasmodern-js": () => import("/zeit/2316462f/packages/landing-gatsby/src/pages/saasmodern.js" /* webpackChunkName: "component---src-pages-saasmodern-js" */)
}

